.item {
    min-height: 20rem;
    min-width: 30rem;
    padding: 40px;
    pointer-events: none;
}

.item img {
	width: 100%;
    height: 100%;
    border-radius: 2rem;
}

.inner-carousel {
	display: flex;
	background: rgb(0, 0, 0);
}

.carousel {
	display: flex;
    cursor: grab;
    background: rgb(0, 0, 0);
	width: 500px;
	overflow: hidden;
}