@keyframes gradient-xy {
    0%, 100% {
        background-size: 400% 400%;
        background-position: left center;
    }
    50% {
        background-size: 200% 200%;
        background-position: right center;
    }
}
.animate-gradient-xy {
    animation: gradient-xy 8s ease infinite;
}

/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 40px;
	height: 40px;
	left: 20px;
	top: 100px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
	background: #ffffff;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
	background: #9c9c9c;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
	height: 24px;
	width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
	background: white;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
	padding: 10px;
	background: rgb(167, 37, 160);
	background: radial-gradient(circle, rgb(44, 154, 182) 0%, rgb(167, 37, 160) 100%);
	position: relative;
	height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
	background: #000000;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
	fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
	color: #ffffff;
	padding: 1em;
  }
  
  /* Individual item */
  .bm-item {
	width: 10px;
	display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
	background: rgba(0, 0, 0, 0.3);
  }